import { Table, Button, Input, Select, Collapse, Modal, Tag, DatePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import { ReloadOutlined, CloseOutlined, PlusOutlined, MinusOutlined, EnterOutlined } from '@ant-design/icons';
import { decrypt, encrypt } from '../../utils/encrypt';
import { toast } from 'react-toastify';
import ApiUrl from '../../network';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');
const country = localStorage.getItem('country');
let initialOrder = {};
const Index = () => {
    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        phoneNumber: '',
        orderstatus: '',
        orderNo: '',
        country
    });
    const [orders, setOrders] = useState([]);
    const [orderSelected, setOrderSelected] = useState({});
    const [showEditContent, setShowEditContent] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalCount, setTotalCount] = useState(0);
    const [productType, setProductType] = useState([]);
    const [orderStatus, setOrderStatus] = useState([]);
    const [products, setProducts] = useState([]);
    const [orderType, setOrderType] = useState('');
    const [tab, setTab] = useState('orders');

    // fetch retailers
    const fetchOrders = async (page = currentPage, size = pageSize) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const params = new URLSearchParams({
                page,
                limit: size
            });

            Object.entries(filters).forEach(([key, value]) => {
                if (value !== '') {
                    params.append(key, value);
                }
            });

            const response = await fetch(`${ApiUrl.BaseUrl}/mainorders/miraorders?${params.toString()}`, config);
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();

            if (data.status === true) {
                const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));

                setOrders(decryptedData);
                setTotalCount(decryptedData?.totalCount);
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            toast.error('Failed to fetch retailers.');
        } finally {
            setLoading(false);
        }
    };

    const fetchPartType = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(`${ApiUrl.BaseUrl}/order/v2/orderstatuspartypes`, config);
            const data = await response.json();
            const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
            setProductType(decryptedData?.parttypes);
        } catch (error) {
            //  console.log(error);
        }
    };
    const fetchProducts = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(`${ApiUrl.BaseUrl}/order/v2/allparts`, config);
            const data = await response.json();
            const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));

            setProducts(decryptedData);
        } catch (error) {
            //  console.log(error);
        }
    };

    // fetch routes
    const fetchStatusType = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/order/v2/orderstatuspartypes`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));

                        setOrderStatus(decryptedData?.mirastatus);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    // update order
    const updateOrder = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const datas = {
                id: orderSelected.key,
                newChannelPartnerId: orderSelected?.customerId,
                orderDate: orderSelected.orderDate,
                newOrderStatusId: orderType,
                orderNotes: `Order confirmation placement ${orderSelected?.fulOrderId}`,
                items: orderSelected.items.map((item) => ({
                    skuId: item?.skuId,
                    qty: item?.qty,
                    partType: item?.partType
                }))
            };

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

            const url = `${ApiUrl.BaseUrl}/order/v2/updatemiraorder`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });
            const result = await response.json();

            if (result.status === true) {
                toast.success(result.message);
                setLoading(false);
                setModalOpen(false);
                await fetchOrders();
            } else {
                const error = JSON.parse(decrypt(result.data, keyResult, ivResult));

                toast.error(error);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders().then((r) => r);

        fetchStatusType().then((r) => r);
        fetchPartType().then((r) => r);
        fetchProducts().then((r) => r);
    }, [filters, pageSize, currentPage]);

    const styles = {
        header: {
            backgroundColor: '#0A2938',
            padding: 20,
            borderRadius: 10
        },
        headerContent: {
            fontFamily: 'Poppins',
            fontSize: 30,
            fontWeight: 500,
            lineHeight: '38px',
            textAlign: 'left',
            color: '#0A2938'
        },
        contentHeaderParagraph: {
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'left',
            color: '#0A2938'
        },
        content: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#696E7C'
        },
        filters: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#696E7C'
        },
        filterButton: {
            width: '140px',
            borderRadius: '8px',
            backgroundColor: '#FF740F',
            color: '#fff',
            border: 'none',
            marginRight: '10px'
        },
        resetButton: {
            width: 'fit-content',
            borderRadius: '8px',
            backgroundColor: '#fff',
            color: '#000',
            border: '1px solid #D9DBDE'
        },
        table: {
            borderRadius: '16px',
            backgroundColor: '#fff',
            border: '1px solid #E5E5E5'
        },
        actionButton: {
            backgroundColor: 'transparent',
            color: '#FF740F',
            border: '1px solid #FF740F',
            borderRadius: '8px',
            fontFamily: 'Poppins',
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 500,
            width: 'fit-content'
        },
        modalHeader: {
            fontFamily: 'Poppins',
            fontSize: 24,
            fontWeight: 500,
            lineHeight: '32px',
            textAlign: 'left',
            color: '#E7EAEB'
        },
        modalParagraph: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#E7EAEB'
        },
        saveButton: {
            width: 'fit-content',
            borderRadius: '8px',
            backgroundColor: '#FF740F',
            color: '#fff',
            border: 'none'
        },
        modalHeaderContainer: {
            backgroundColor: '#233E4C',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#E7EAEB',
            padding: 20,
            borderRadius: 10
        }
    };

    const retailerData = orders.map((order) => ({
        key: order?._id, // Unique identifier for each order
        customerId: order?.customerName?._id,
        customerName: order?.customerNameId, // Customer name field
        fulOrderId: order?.fulOrderId,
        placeBy: order?.placeBy, // Person who placed the order
        orderDate: new Date(order?.orderDate).toLocaleDateString(), // Format order date
        salesValue: order?.salesValue, // Sales value
        orderStatus: order?.orderStatus, // Order status
        paymentStatus: order?.paymentStatus?.name, // Payment status name
        orderType: order?.orderType, // Type of the order
        items: order?.items
    }));

    const columns = [
        {
            title: 'Order Date',
            dataIndex: 'orderDate',
            key: 'orderDate',
            render: (orderDate) => new Date(orderDate).toLocaleDateString() // Formats the date
        },
        {
            title: 'Order number',
            dataIndex: 'fulOrderId',
            key: 'fulOrderId'
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName'
        },
        {
            title: 'Placed By',
            dataIndex: 'placeBy',
            key: 'placeBy'
        },
        {
            title: 'Total value',
            dataIndex: 'salesValue',
            key: 'salesValue'
        },
        {
            title: 'Order Status',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            render: (status) => <Tag color={status.toLowerCase() === 'received' ? 'green' : 'red'}>{status}</Tag>
        },
        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            render: (paymentStatus) => <Tag color={paymentStatus.toLowerCase() === 'pending' ? 'yellow' : 'green'}>{paymentStatus}</Tag>
        },

        {
            title: 'Order Type',
            dataIndex: 'orderType',
            key: 'orderType'
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Button
                    type="primary"
                    onClick={() => {
                        setOrderSelected(record);
                        initialOrder = record;
                        setModalOpen(true);
                    }}
                    style={{
                        backgroundColor: 'transparent',
                        borderColor: '#FF740F',
                        color: '#FF740F'
                    }}
                >
                    Place Order
                </Button>
            )
        }
    ];

    const applyFilters = () => {
        // Apply the filters to the retailers data
        const filteredRetailers = orders.filter((retailer) => {
            const nameMatch = retailer.partnerName.toLowerCase().includes(filters.search.toLowerCase());
            const routeMatch = !filters.routes || retailer.route === filters.routes;
            const podMatch = !filters.pods || retailer.pod.name === filters.pods;
            return nameMatch && routeMatch && podMatch;
        });

        // Update the retailers state with the filtered data
        setOrders(filteredRetailers);

        // Reset pagination to the first page
        setCurrentPage(1);

        // Fetch the filtered data from the API
        fetchOrders();
    };

    const resetFilters = () => {
        setFilters({
            search: '',
            routes: '',
            pods: ''
        });
        setCurrentPage(1);
        setPageSize(25);
        fetchOrders();
    };

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
        fetchOrders(pagination.current, pagination.pageSize);
    };

    // Handle date range selection
    const handleDateChange = (dates, dateStrings) => {
        if (dates) {
            setFilters({
                ...filters,
                startDate: dayjs(dates[0]).format('YYYY-MM-DD'),
                endDate: dayjs(dates[1]).format('YYYY-MM-DD')
            });
        } else {
            setFilters({
                ...filters,
                startDate: '',
                endDate: ''
            });
        }
    };

    const handleMakeChanges = () => {
        setShowEditContent(true);
    };

    // Function to add new item
    const addNewItem = (value) => {
        const newProduct = products.find((product) => product._id === value);
        if (newProduct) {
            const newOrderItem = {
                _id: new Date().getTime().toString(), // Temporary unique ID
                partid: newProduct.sku,
                skuId: newProduct._id,
                qty: 1,
                partType: newProduct.partType || 'Pieces',
                garagePrice: newProduct.garagePrice || 0,
                partimage: newProduct.partsImage || '' // Default image if needed
            };
            setOrderSelected((prev) => ({
                ...prev,
                items: [...prev.items, newOrderItem]
            }));
        }
    };

    return (
        <div className="verification">
            <div className="header">
                <div className="header-content">
                    <h1 style={styles.headerContent}>DASHBOARD OVERVIEW</h1>
                    <p style={styles.contentHeaderParagraph}>View order performance</p>
                </div>
                <Button
                    style={{
                        backgroundColor: 'transparent',
                        color: '#FF740F',
                        borderColor: '#FF740F',
                        width: 'fit-content',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                    onClick={() => (window.location.href = '/mira/inbox-messages')}
                >
                    <EnterOutlined style={{ fontSize: '16px', marginRight: '2px' }} /> Mira Chat
                </Button>
            </div>
            <div className="content">
                <div className="content-header">
                    <Collapse accordion>
                        <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                            <div className="filters" style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', marginBottom: '16px' }}>
                                <RangePicker
                                    onChange={handleDateChange}
                                    value={filters.startDate && filters.endDate ? [dayjs(filters.startDate), dayjs(filters.endDate)] : null}
                                />
                                <Input
                                    placeholder="Search by order No"
                                    style={{ width: '200px' }}
                                    onChange={(e) => setFilters({ ...filters, orderNo: e.target.value })}
                                    value={filters.orderNo}
                                />
                                <Input
                                    placeholder="Search by phone Number"
                                    style={{ width: '200px' }}
                                    onChange={(e) => setFilters({ ...filters, phoneNumber: e.target.value })}
                                    value={filters.phoneNumber}
                                />
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="label"
                                    options={orderStatus.map((status) => ({
                                        label: status.name,
                                        value: status.name
                                    }))}
                                    filterOption={(inputValue, option) => {
                                        // This function is used to filter options in a Select component
                                        // It compares the input value with the label of each option

                                        // Convert both the input and the option label to uppercase for case-insensitive comparison
                                        const upperInput = inputValue.toUpperCase();

                                        // Check if the option label is a string
                                        if (typeof option.label === 'string') {
                                            // If it's a string, directly check if the uppercase input is included in the uppercase label
                                            return option.label.toUpperCase().indexOf(upperInput) !== -1;
                                        }

                                        // If the label is not a string (e.g., it might be a number or other type)
                                        // Convert it to a string before comparing
                                        return String(option.label).toUpperCase().indexOf(upperInput) !== -1;
                                    }}
                                    placeholder="Search by order status"
                                    style={{ width: '200px' }}
                                    onChange={(value) => setFilters({ ...filters, orderstatus: value })}
                                    value={filters.orderstatus || null}
                                />

                                <div className="action_button">
                                    <Button style={styles.filterButton} onClick={applyFilters}>
                                        Apply filter
                                    </Button>
                                    <Button style={styles.resetButton} onClick={resetFilters}>
                                        <ReloadOutlined />
                                    </Button>
                                </div>
                            </div>
                        </Collapse.Panel>
                    </Collapse>
                </div>
                <div className="content-body">
                    <div className="table-responsive" style={styles.table}>
                        <Table
                            rowKey={(record) => record.key}
                            columns={columns}
                            dataSource={retailerData}
                            loading={loading}
                            pagination={{
                                current: currentPage,
                                pageSize,
                                total: totalCount
                            }}
                            onChange={handleTableChange}
                            scroll={{ x: 1000 }}
                        />
                    </div>
                </div>
            </div>
            <Modal open={modalOpen} footer={null} onCancel={() => setModalOpen(false)} width={700} zIndex={999}>
                <div>
                    <div className="modal-header" style={styles.modalHeaderContainer}>
                        <div>
                            <h1 style={styles.modalHeader}>Order Verification and Placement</h1>
                            <p style={styles.modalParagraph}>
                                Please review the order details below. Make any necessary changes or confirm to proceed with the order.
                            </p>
                        </div>
                        {showEditContent === true && (
                            <Button
                                style={{ backgroundColor: '#FF740F', color: '#fff', width: 'fit-content' }}
                                onClick={() => {
                                    setShowEditContent(false);
                                    setOrderSelected(initialOrder);
                                }}
                            >
                                Cancel
                            </Button>
                        )}
                    </div>
                    <div className="modal-body">
                        {showEditContent === false && (
                            <div className="question-field" style={{ textAlign: 'center' }}>
                                <p style={{ fontSize: '20px' }}>
                                    Would you like to make changes to this proforma before placing the order?
                                </p>
                                <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
                                    <Button
                                        style={{
                                            backgroundColor: 'transparent',
                                            color: '#FF740F',
                                            borderColor: '#FF740F',
                                            width: 'fit-content'
                                        }}
                                        onClick={handleMakeChanges}
                                    >
                                        Yes, Make Changes
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: '#FF740F', color: '#fff', width: 'fit-content' }}
                                        onClick={() => {
                                            setTab('orderType');
                                            setShowEditContent(true);
                                        }}
                                    >
                                        No, Place Order
                                    </Button>
                                </div>
                            </div>
                        )}
                        {showEditContent && (
                            <div>
                                {tab === 'orders' && (
                                    <div>
                                        {orderSelected?.items?.map((order, index) => (
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }} key={order._id}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '10px',
                                                        marginBottom: '20px'
                                                    }}
                                                    key={order._id}
                                                >
                                                    <div
                                                        style={{
                                                            width: '140px',
                                                            height: '140px',
                                                            objectFit: 'cover',
                                                            backgroundColor: '#CED4D7',
                                                            borderRadius: '10px',
                                                            padding: '5px'
                                                        }}
                                                    >
                                                        <img
                                                            src={order.partimage}
                                                            alt="product"
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover',
                                                                borderRadius: '10px'
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <p
                                                            style={{
                                                                fontSize: '14px',
                                                                marginBottom: '2px',
                                                                paddingBottom: '0px'
                                                            }}
                                                        >
                                                            <strong> Product name:</strong> {order.partid}
                                                        </p>
                                                        <p
                                                            style={{
                                                                fontSize: '14px',
                                                                marginBottom: '2px',
                                                                paddingBottom: '0px'
                                                            }}
                                                        >
                                                            <strong> Product price:</strong> {order.garagePrice.toFixed(2)}
                                                        </p>

                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: '10px',
                                                                marginTop: '10px'
                                                            }}
                                                        >
                                                            <span>
                                                                <strong>Part type:</strong>{' '}
                                                                <Select
                                                                    showSearch
                                                                    allowClear
                                                                    placeholder="Select part type"
                                                                    options={productType.map((type) => ({
                                                                        value: type.name,
                                                                        label: type.name
                                                                    }))}
                                                                    value={order.partType}
                                                                    onChange={(value) => {
                                                                        const updatedProducts = orderSelected.items.map((p) =>
                                                                            p._id === order._id ? { ...p, partType: value } : p
                                                                        );
                                                                        setOrderSelected((prev) => ({
                                                                            ...prev,
                                                                            items: updatedProducts
                                                                        }));
                                                                    }}
                                                                    filterOption={(input, option) =>
                                                                        option.label.toLowerCase().startsWith(input.toLowerCase())
                                                                    }
                                                                    style={{ width: '100px', marginLeft: '40px' }}
                                                                />
                                                            </span>
                                                        </div>

                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: '10px',
                                                                marginTop: '10px'
                                                            }}
                                                        >
                                                            <span>
                                                                <strong>Quantity</strong>
                                                            </span>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: '10px',
                                                                    marginLeft: '40px'
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        backgroundColor: '#193B56',
                                                                        padding: '5px',
                                                                        borderRadius: '5px'
                                                                    }}
                                                                    role="button"
                                                                    tabIndex={0}
                                                                    onClick={() => {
                                                                        const updatedProducts = orderSelected.items.map((p) =>
                                                                            p._id === order._id
                                                                                ? {
                                                                                      ...p,
                                                                                      qty: Math.max(1, (p.qty || 0) - 1) // Ensure minimum qty of 1
                                                                                  }
                                                                                : p
                                                                        );
                                                                        setOrderSelected((prev) => ({
                                                                            ...prev,
                                                                            items: updatedProducts
                                                                        }));
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter' || e.key === ' ') {
                                                                            const updatedProducts = orderSelected.items.map((p) =>
                                                                                p._id === order._id
                                                                                    ? {
                                                                                          ...p,
                                                                                          qty: Math.max(1, (p.qty || 0) - 1)
                                                                                      }
                                                                                    : p
                                                                            );
                                                                            setOrderSelected(updatedProducts);
                                                                        }
                                                                    }}
                                                                >
                                                                    <MinusOutlined style={{ color: '#fff' }} />
                                                                </span>
                                                                <span>{order.qty || 0}</span>
                                                                <span
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        backgroundColor: '#193B56',
                                                                        padding: '5px',
                                                                        borderRadius: '5px'
                                                                    }}
                                                                    onClick={() => {
                                                                        const updatedProducts = orderSelected.items.map((p) =>
                                                                            p._id === order._id
                                                                                ? {
                                                                                      ...p,
                                                                                      qty: (p.qty || 0) + 1
                                                                                  }
                                                                                : p
                                                                        );
                                                                        setOrderSelected((prev) => ({
                                                                            ...prev,
                                                                            items: updatedProducts
                                                                        }));
                                                                    }}
                                                                    role="button"
                                                                    tabIndex={0}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter' || e.key === ' ') {
                                                                            const updatedProducts = orderSelected.items.map((p) =>
                                                                                p._id === order._id
                                                                                    ? {
                                                                                          ...p,
                                                                                          qty: (p.qty || 0) + 1
                                                                                      }
                                                                                    : p
                                                                            );
                                                                            setOrderSelected((prev) => ({
                                                                                ...prev,
                                                                                items: updatedProducts
                                                                            }));
                                                                        }
                                                                    }}
                                                                >
                                                                    <PlusOutlined style={{ color: '#fff' }} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Remove Item */}
                                                {orderSelected?.items.length > 1 && (
                                                    <Button
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            backgroundColor: '#FA3246',
                                                            color: '#FFF',
                                                            width: 'fit-content',
                                                            height: 'fit-content',
                                                            fontSize: '16px'
                                                        }}
                                                        type="danger"
                                                        onClick={() => {
                                                            const updatedProducts = orderSelected.items.filter((p) => p._id !== order._id);
                                                            setOrderSelected((prev) => ({
                                                                ...prev,
                                                                items: updatedProducts
                                                            }));
                                                        }}
                                                    >
                                                        <CloseOutlined />
                                                    </Button>
                                                )}
                                            </div>
                                        ))}
                                        <div style={{ marginTop: '20px' }}>
                                            <Select
                                                showSearch
                                                allowClear
                                                placeholder="Add More Items"
                                                style={{ width: '200px' }}
                                                options={products.map((product) => ({
                                                    value: product._id,
                                                    label: product.sku
                                                }))}
                                                onChange={addNewItem}
                                                filterOption={(inputValue, option) => {
                                                    const upperInput = inputValue.toUpperCase();
                                                    return (
                                                        typeof option.label === 'string' && option.label.toUpperCase().includes(upperInput)
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {tab === 'orderType' && (
                                    <div style={{ padding: '10px' }}>
                                        <p style={{ fontSize: '16px', marginBottom: '5px' }}>Select order type</p>
                                        <Select
                                            allowClear
                                            showSearch
                                            optionFilterProp="label"
                                            options={orderStatus.map((status) => ({
                                                label: status.name,
                                                value: status._id
                                            }))}
                                            filterOption={(inputValue, option) => {
                                                // This function is used to filter options in a Select component
                                                // It compares the input value with the label of each option

                                                // Convert both the input and the option label to uppercase for case-insensitive comparison
                                                const upperInput = inputValue.toUpperCase();

                                                // Check if the option label is a string
                                                if (typeof option.label === 'string') {
                                                    // If it's a string, directly check if the uppercase input is included in the uppercase label
                                                    return option.label.toUpperCase().indexOf(upperInput) !== -1;
                                                }

                                                // If the label is not a string (e.g., it might be a number or other type)
                                                // Convert it to a string before comparing
                                                return String(option.label).toUpperCase().indexOf(upperInput) !== -1;
                                            }}
                                            placeholder="Search by order status"
                                            style={{ width: '300px' }}
                                            onChange={(value) => setOrderType(value)}
                                            value={orderType}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="modal-footer">
                        {showEditContent && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    style={{
                                        backgroundColor: 'transparent',
                                        color: '#FF740F',
                                        borderColor: '#FF740F',
                                        width: 'fit-content'
                                    }}
                                    onClick={() => {
                                        setShowEditContent(false);
                                        setOrderSelected(initialOrder);
                                    }}
                                >
                                    Cancel
                                </Button>

                                {tab === 'orders' && (
                                    <Button
                                        style={{
                                            backgroundColor: '#FF740F',
                                            color: '#fff',
                                            width: 'fit-content',
                                            marginLeft: '5px'
                                        }}
                                        onClick={() => setTab('orderType')}
                                    >
                                        Continue
                                    </Button>
                                )}
                                {tab === 'orderType' && (
                                    <Button
                                        style={{
                                            backgroundColor: '#FF740F',
                                            color: '#fff',
                                            width: 'fit-content',
                                            marginLeft: '5px'
                                        }}
                                        onClick={() => updateOrder()}
                                    >
                                        Place Order
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Index;
