import { CloudDownloadOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Popover } from 'antd';
import dayjs from 'dayjs';
import React, { useRef } from 'react';

export const Index = ({ messages, chatBoxRef, phoneNumber, actionContent, open, handleChatPopClick }) => {
    const loggedInFirstName = localStorage.getItem('userFirstName');
    const loggedInLastName = localStorage.getItem('userLastName');
    const messageRefs = useRef({});

    // Combine the first and last name for comparison
    const loggedInUserFullName = `${loggedInFirstName} ${loggedInLastName}`;

    // Scroll to the replied message
    const scrollToMessage = (messageId) => {
        const targetMessage = messageRefs.current[messageId];
        if (targetMessage) {
            targetMessage.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const renderDeliveryStatusIcon = (message) => {
        if (message?.agent === 'staff' || message?.agent === 'mira') {
            if (message?.delivery === 'sent') {
                return (
                    <span className="status-icon" aria-label="Message sent">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.74882 11.9712C6.3601 12.3635 5.72694 12.3664 5.33462 11.9777L0.740968 7.42617C0.348648 7.03745 0.345731 6.40429 0.734452 6.01197L0.816568 5.92909C1.20529 5.53677 1.83845 5.53386 2.23077 5.92258L5.32083 8.98429C5.71315 9.37301 6.3463 9.37009 6.73503 8.97777L14.9196 0.717469C15.3083 0.32515 15.9414 0.322233 16.3338 0.710954L16.4166 0.79307C16.8089 1.18179 16.8119 1.81495 16.4231 2.20727L6.74882 11.9712Z"
                                fill="#D9DBDE"
                            />
                        </svg>
                    </span>
                );
            }
            if (message?.delivery === 'delivered') {
                return (
                    <span className="status-icon" aria-label="Message delivered">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.74882 11.9712C6.3601 12.3635 5.72694 12.3664 5.33462 11.9777L0.740968 7.42617C0.348648 7.03745 0.345731 6.40429 0.734452 6.01197L0.816568 5.92909C1.20529 5.53677 1.83845 5.53386 2.23077 5.92258L5.32083 8.98429C5.71315 9.37301 6.3463 9.37009 6.73503 8.97777L14.9196 0.717469C15.3083 0.32515 15.9414 0.322233 16.3338 0.710954L16.4166 0.79307C16.8089 1.18179 16.8119 1.81495 16.4231 2.20727L6.74882 11.9712Z"
                                fill="#D9DBDE"
                            />
                            <path
                                d="M6.74882 17.2622C6.3601 17.6545 5.72694 17.6574 5.33462 17.2687L0.740968 12.7172C0.348648 12.3285 0.345731 11.6953 0.734452 11.303L0.816568 11.2201C1.20529 10.8278 1.83845 10.8249 2.23077 11.2136L5.32083 14.2753C5.71315 14.664 6.3463 14.6611 6.73503 14.2688L14.9196 6.00848C15.3083 5.61617 15.9414 5.61325 16.3338 6.00197L16.4166 6.08409C16.8089 6.47281 16.8119 7.10596 16.4231 7.49828L6.74882 17.2622Z"
                                fill="#D9DBDE"
                            />
                        </svg>
                    </span>
                );
            }
            if (message?.delivery === 'read') {
                return (
                    <span className="status-icon" aria-label="Message read">
                        <svg width="20" height="10" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.625 7.625L5.375 11.375M10.625 5.375L14.375 1.625M7.625 7.625L11.375 11.375L20.375 1.625"
                                stroke="#00AA55"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                );
            }
        }
        return null;
    };

    return (
        <div id="chat-box-message" className="chat-box-message" ref={chatBoxRef}>
            {messages
                .filter((message) => message.phone === phoneNumber.phone)
                .reduce((acc, message, index, array) => {
                    const messageDate = dayjs(message.createdAt);
                    const prevMessage = array[index - 1];
                    const prevMessageDate = prevMessage ? dayjs(prevMessage.createdAt) : null;

                    // Store each message in the ref by message id
                    const messageRef = (el) => {
                        if (el) {
                            messageRefs.current[message._id] = el;
                        }
                    };

                    if (!prevMessageDate || !messageDate.isSame(prevMessageDate, 'day')) {
                        acc.push(
                            <div key={`date-${message._id}`} className="message-date-separator">
                                <span>{messageDate.format('MMMM D, YYYY')}</span>
                            </div>
                        );
                    }

                    // Find the replied message if `reply_to` and `whatsapp_id` match
                    // eslint-disable-next-line array-callback-return

                    const repliedMessage = messages.find((msg) => {
                        // Ensure message.replyTo is not null before comparison
                        if (message.replyTo) {
                            // Compare msg.whatsapp_id with both message.replyTo and message.whatsapp_reply
                            return msg.whatsapp_id === message.replyTo || msg.whatsapp_id === message.whatsapp_reply;
                        }
                        return false; // If replyTo is null, skip the comparison
                    });

                    acc.push(
                        <div
                            key={message._id}
                            className={message.isSent ? 'chat-box-message-send' : 'chat-box-message-recieve'}
                            ref={messageRef}
                        >
                            <div className="message">
                                <div>
                                    {message?.user && (
                                        <span className="d-flex justify-content-between align-items-center">
                                            <small
                                                style={{
                                                    color: '#819AFF',
                                                    fontSize: '12px',
                                                    marginBottom: '5px',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {message.user === loggedInUserFullName ? 'You' : message.user}
                                            </small>
                                            <br />
                                            <Dropdown
                                                overlay={actionContent(message)}
                                                trigger={['click']}
                                                placement="top"
                                                key={message._id}
                                                className="popoverChat"
                                                open={open === message._id}
                                                onOpenChange={() => handleChatPopClick(message._id)}
                                                style={{ width: 'fit-content' }}
                                            >
                                                <Button style={{ width: 'fit-content', backgroundColor: 'transparent' }}>
                                                    <MoreOutlined />
                                                </Button>
                                            </Dropdown>
                                        </span>
                                    )}

                                    {/* Display replied message if available */}
                                    {repliedMessage && (
                                        <div
                                            role="button"
                                            tabIndex={0} // Makes the div focusable by keyboard
                                            aria-label={`Scroll to message from ${
                                                repliedMessage.user === loggedInUserFullName ? 'You' : repliedMessage.user
                                            }`}
                                            className="replied-message"
                                            style={{
                                                padding: '5px',
                                                borderLeft: '3px solid #ccc',
                                                marginBottom: '10px',
                                                color: '#666',
                                                fontSize: '12px',
                                                cursor: 'pointer' // Show it's clickable
                                            }}
                                            onClick={() => scrollToMessage(repliedMessage._id)}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter' || e.key === ' ') {
                                                    scrollToMessage(repliedMessage._id); // Trigger scroll on key press (Enter or Space)
                                                }
                                            }}
                                        >
                                            <small>
                                                Replying to {repliedMessage.user === loggedInUserFullName ? 'You' : repliedMessage.user}:
                                            </small>
                                            <p>{repliedMessage.content}</p>
                                        </div>
                                    )}

                                    {message.content &&
                                        // eslint-disable-next-line consistent-return
                                        (() => {
                                            const excludedExtensions = ['.pdf', '.jpeg', '.mp4'];
                                            const hasExcludedExtension = excludedExtensions.some((ext) => message.content.endsWith(ext));

                                            if (!hasExcludedExtension) {
                                                return (
                                                    <span className="mt-4">
                                                        <p>{message.content}</p>
                                                    </span>
                                                );
                                            }
                                        })()}

                                    {message.type === 'image' && message.image && (
                                        <div className="mt-4 mb-4">
                                            <span
                                                key={index}
                                                role="button"
                                                tabIndex={0}
                                                aria-label="View image"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' || e.key === ' ') {
                                                        // Trigger any action on key press if necessary
                                                    }
                                                }}
                                                style={{
                                                    display: 'block',
                                                    marginBottom: '2px'
                                                }} // Ensure images are block elements
                                            >
                                                <img
                                                    src={message.image}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = 'https://via.placeholder.com/150';
                                                    }}
                                                    alt={`img-${index}`} // Unique alt attribute for accessibility
                                                    style={{
                                                        width: '100%',
                                                        height: '200px',
                                                        objectFit: 'cover',
                                                        marginTop: '2px'
                                                    }}
                                                />
                                            </span>
                                        </div>
                                    )}

                                    {message.type === 'images' && message.image && Array.isArray(message.image) && (
                                        <div className="mt-4 mb-4">
                                            {message.image.map((imgUrl, index) => (
                                                <span
                                                    key={index}
                                                    role="button"
                                                    tabIndex={0}
                                                    aria-label="View image"
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter' || e.key === ' ') {
                                                            // Trigger any action on key press if necessary
                                                        }
                                                    }}
                                                    style={{
                                                        display: 'block',
                                                        marginBottom: '2px'
                                                    }} // Ensure images are block elements
                                                >
                                                    <img
                                                        src={imgUrl}
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = 'https://via.placeholder.com/150';
                                                        }}
                                                        alt={`img-${index}`} // Unique alt attribute for accessibility
                                                        style={{
                                                            width: '100%',
                                                            height: '200px',
                                                            objectFit: 'cover',
                                                            marginTop: '2px'
                                                        }}
                                                    />
                                                </span>
                                            ))}
                                        </div>
                                    )}

                                    {message.type === 'document' && message.image && (
                                        <div className="mt-4 mb-4">
                                            <span
                                                key={index}
                                                role="button"
                                                tabIndex={0}
                                                aria-label="View document"
                                                style={{
                                                    display: 'block',
                                                    marginBottom: '20px'
                                                }} // Margin between documents
                                            >
                                                <iframe
                                                    src={`${message.image}#toolbar=0`}
                                                    style={{
                                                        border: 'none',
                                                        width: '100%',
                                                        height: '200px',
                                                        marginTop: '2px',
                                                        marginBottom: '2px'
                                                    }}
                                                    title={`PDF Document Preview ${index + 1}`} // Unique title for accessibility
                                                />
                                                <div className="pdf-download-content">
                                                    <span>
                                                        <img
                                                            className="pdf-info"
                                                            src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                                                            alt="pdf"
                                                            style={{
                                                                width: '50px',
                                                                height: '50px'
                                                            }}
                                                        />
                                                        {/* {message.content} */}
                                                    </span>
                                                    <Button
                                                        onClick={() => window.open(message.image, '_blank')}
                                                        className="pdf-download-btn"
                                                    >
                                                        <CloudDownloadOutlined />
                                                    </Button>
                                                </div>
                                            </span>
                                        </div>
                                    )}
                                    {message.type === 'documents' && message.image && Array.isArray(message.image) && (
                                        <div className="mt-4 mb-4">
                                            {message.image.map((docUrl, index) => (
                                                <span
                                                    key={index}
                                                    role="button"
                                                    tabIndex={0}
                                                    aria-label="View document"
                                                    style={{
                                                        display: 'block',
                                                        marginBottom: '20px'
                                                    }} // Margin between documents
                                                >
                                                    <iframe
                                                        src={`${docUrl}#toolbar=0`}
                                                        style={{
                                                            border: 'none',
                                                            width: '100%',
                                                            height: '200px',
                                                            marginTop: '2px',
                                                            marginBottom: '2px'
                                                        }}
                                                        title={`PDF Document Preview ${index + 1}`} // Unique title for accessibility
                                                    />
                                                    <div className="pdf-download-content">
                                                        <span>
                                                            <img
                                                                className="pdf-info"
                                                                src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                                                                alt="pdf"
                                                                style={{
                                                                    width: '50px',
                                                                    height: '50px'
                                                                }}
                                                            />
                                                            {/* {message.content} */}
                                                        </span>
                                                        <Button onClick={() => window.open(docUrl, '_blank')} className="pdf-download-btn">
                                                            <CloudDownloadOutlined />
                                                        </Button>
                                                    </div>
                                                </span>
                                            ))}
                                        </div>
                                    )}

                                    {message.type === 'audio' && (
                                        <span>
                                            <audio controls className="audio-preview">
                                                <source src={message.image} type="audio/mpeg" />
                                                <source src={message.image} type="audio/ogg" />
                                                <source src={message.image} type="audio/wav" />
                                                <track kind="captions" src="" label="English captions" />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </span>
                                    )}
                                    {message.type === 'video' && message.image && (
                                        <div className="mt-4 mb-4">
                                            <span
                                                role="button"
                                                tabIndex={0}
                                                aria-label="View video"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' || e.key === ' ') {
                                                        // You can trigger any additional actions for accessibility if needed
                                                    }
                                                }}
                                                style={{
                                                    display: 'block',
                                                    marginBottom: '2px'
                                                }} // Ensuring the video container is block
                                            >
                                                <video
                                                    width="100%" // Adjust video width
                                                    height="auto" // Maintain aspect ratio
                                                    controls // Display video controls (play, pause, volume, etc.)
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = 'fallback-video-url.mp4'; // Provide a fallback video in case of error
                                                    }}
                                                    style={{
                                                        objectFit: 'cover', // Ensures proper scaling of the video
                                                        marginTop: '2px'
                                                    }}
                                                >
                                                    <source src={message.image} type="video/mp4" />
                                                    <source src={message.image} type="video/webm" />
                                                    <source src={message.image} type="video/ogg" />
                                                    {/* Track for captions */}
                                                    <track kind="captions" srcLang="en" label="English captions" default />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </span>
                                        </div>
                                    )}

                                    <small
                                        style={{
                                            color: '#819AFF',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginTop: '10px'
                                        }}
                                    >
                                        <small className="mr-1"> {dayjs(message.createdAt).format('hh:mm A')}</small>
                                        {renderDeliveryStatusIcon(message)}
                                    </small>
                                </div>
                            </div>
                        </div>
                    );

                    return acc;
                }, [])}
        </div>
    );
};

export default Index;
