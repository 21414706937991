import './mira.css';
import {
    CloseOutlined,
    CommentOutlined,
    FileImageOutlined,
    FilePdfOutlined,
    InboxOutlined,
    LeftOutlined,
    LoadingOutlined,
    PlusOutlined,
    SendOutlined,
    AudioOutlined,
    DownOutlined,
    ShoppingCartOutlined,
    FileDoneOutlined,
    ProfileOutlined,
    MinusOutlined,
    ArrowLeftOutlined,
    EditOutlined,
    FieldTimeOutlined,
    VideoCameraAddOutlined
} from '@ant-design/icons';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import { Button, Menu, Modal, Popover, Spin, Select, Table, Input, Upload, Dropdown, Divider, Tag } from 'antd';
import ApiUrl from '../../network';
import normFile from '../../imageToBucket';
import { decrypt, encrypt } from '../../utils/encrypt';
import ChatBoxMessage from './patials/ChatBox';

import { timeAgo } from './timer';
import { calculateChatExpiration } from './calculateChatExpiration';
import dayjs from 'dayjs';

const localizedFormat = require('dayjs/plugin/localizedFormat');
const duration = require('dayjs/plugin/duration');
const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const miraURL = ApiUrl.MiraUrl;
const country = localStorage.getItem('country') || 'Ghana';
const baseUrl = `${miraURL}/api/v1/whatsapp`; // `${miraURL}/api/v1/whatsapp`; // 'https://mira.garagemobility.com/api/v1/whatsapp'; // ; ;
const socketUrl = `${miraURL}/`; // WebSocket endpoint

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');
const logo = '../../garagelogo.png';

export const Index = () => {
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(null);
    const [loading, setLoading] = useState(false);
    const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
    const [receiptLoading, setReceiptLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [socket, setSocket] = useState(null);
    const [messageType, setMessageType] = useState('text');
    const [imageUrl, setImageUrl] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [replyTo, setReplyTo] = useState({
        _id: null,
        profile: null,
        content: null,
        whatsapp_id: null
    });
    const [switchChat, setSwitchChat] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState({});
    const chatBoxRef = useRef(null);
    const [imageModal, setImageModal] = useState(false);
    const [replyType, setReplyType] = useState('text');
    const [accept, setAccept] = useState('image/*');
    const [isSenderListVisible, setSenderListVisible] = useState(true);
    const [isChatBoxVisible, setChatBoxVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [chatMenuVisible, setChatMenuVisible] = useState(false);
    const [chatMenuVisibleMobile, setChatMenuVisibleMobile] = useState(false);
    const [chatFilter, setChatFilter] = useState('all');
    const [placeOrder, setPlaceOrder] = useState(false);
    const [arrow, setArrow] = useState('Show');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState([]);
    const [productType, setProductType] = useState([]);
    const [tab, setTab] = useState('products');
    const [customerName, setCustomerName] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [customerLocation, setCustomerLocation] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [customerLoader, setCustomerLoader] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isRecording, setIsRecording] = useState(false); // Track recording state
    const [recordingTime, setRecordingTime] = useState(0); // Track recording duration
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const recordingTimerRef = useRef(null); // Reference for timer
    const [audioModal, setAudioModal] = useState(false);

    const [audioPreviewUrl, setAudioPreviewUrl] = useState(null);
    const [recordedAudio, setRecordedAudio] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [audioFile, setAudioFile] = useState('');
    const fileInputRef = useRef(null);
    const [qty, setQty] = useState('');
    const [partQtyInPieces, setPartQtyInPieces] = useState('');
    const [expiredAt, setExpiredAt] = useState('');
    const [timeRemaining, setTimeRemaining] = useState('');
    const [isAtBottom, setIsAtBottom] = useState(true);

    // eslint-disable-next-line camelcase
    let userHolder = '';

    const fetchUsers = async () => {
        try {
            // setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(`${baseUrl}/users?country=${country}&limit=2000`, config);
            const data = await response.json();
            setUsers(data?.users);
            setLoading(false);
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const fetchCustomer = async () => {
        try {
            setCustomerLoader(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/order/v2/searchcustomers?phoneNumber=${phoneNumber.phone}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));

                        if (result === null) {
                            toast.info('Customer not in our system');
                            setCustomerPhone(phoneNumber?.phone);
                        } else if (result.length === 0) {
                            toast.info('Customer not in our system');
                            setCustomerPhone(phoneNumber?.phone);
                        } else {
                            toast.success('Customer fetch successfully ');
                            setCustomerId(result?._id);
                            setCustomerName(result?.partnerName);
                            setCustomerPhone(result?.partnerContact);
                            setCustomerLocation(result?.location);
                        }
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error);
                    }
                });

            setCustomerLoader(false);
        } catch (error) {
            toast.error(error.message);
            setCustomerLoader(false);
        } finally {
            setCustomerLoader(false);
        }
    };

    const transformMessage = (data) => {
        return {
            phone: data.phone,
            content: data?.content?.body,
            image: data?.content?.link,
            type: data?.content?.type,
            isSent: data?.agent === 'mira' || data?.agent === 'staff',
            agent: data?.agent,
            user: selectedUser?.profile,
            replyTo: data?.whatsapp_reply,
            delivery: data?.delivery,
            createdAt: data?.createdAt
        };
    };

    const updateUsersLastMessageTime = (data) => {
        setUsers((prevUsers) => {
            const updatedUsers = prevUsers.map((user) => {
                if (user.phone === data.phone) {
                    return {
                        ...user,
                        lastMessageTime: data?.createdAt // Update the last message time
                    };
                }
                return user;
            });

            // Sort users by last message time, most recent first
            return updatedUsers.sort((a, b) => {
                const dateA = new Date(a.lastMessageTime || 0);
                const dateB = new Date(b.lastMessageTime || 0);
                return dateB - dateA;
            });
        });
    };

    const setupSocket = () => {
        const newSocket = io(socketUrl, {
            transports: ['websocket'],
            path: '/socket.io'
        });

        newSocket.on('connect', () => {
            // console.log('Connected');
        });

        newSocket.on('mira_chat', (data) => {
            console.log(data, 'mira data');

            // Transform and update message
            const transformedMessage = transformMessage(data);
            setMessages((prevMessages) => [...prevMessages, transformedMessage]);

            // Update user's last message timestamp
            updateUsersLastMessageTime(data);
        });

        newSocket.on('mira_status', (data) => {
            console.log(data, 'mira status');

            // Extract necessary details from the data object
            // eslint-disable-next-line camelcase
            const { whatsapp_id, phone, status } = data;

            // Update the message delivery status based on whatsapp_id
            setMessages((prevMessages) =>
                prevMessages.map(
                    (message) =>
                        // eslint-disable-next-line camelcase
                        message.whatsapp_id === whatsapp_id || message.phone === phone
                            ? { ...message, delivery: status } // Update only the delivery status
                            : message // Return unchanged messages
                )
            );
        });

        newSocket.on('disconnect', () => {
            // console.log('Socket.IO disconnected');
        });

        newSocket.on('connect_error', (error) => {
            // console.log('Socket.IO connect_error:', error);
        });

        setSocket(newSocket);
    };

    const sendMessage = () => {
        if ((socket && message.trim()) || !message) {
            const newMessage = {
                phone: selectedUser.phone,
                content: {
                    body: message,
                    type: messageType, // 'text' or 'image',
                    url: messageType === 'image' || messageType === 'document' || messageType === 'video' ? imageUrl : ''
                },
                user_id: localStorage.getItem('userId'),
                replyTo: replyTo.whatsapp_id
            };

            socket.emit('mira_chat', newMessage);

            // Transform message content
            const transformedMessage = {
                _id: Date.now(),
                phone: selectedUser.phone,
                content: message,
                type: messageType,
                isSent: 'mira' || 'staff',
                agent: 'staff',
                image: messageType === 'image' || messageType === 'document' || messageType === 'video' ? imageUrl : null,
                user: `${localStorage.getItem('userFirstName')} ${localStorage.getItem('userLastName')}`,
                createdAt: new Date().toISOString()
            };

            setMessages((prevMessages) => [...prevMessages, transformedMessage]);

            // eslint-disable-next-line no-use-before-define
            closePopover();

            setMessage('');
            setImageUrl([]);
            setFileList([]);
            setImageModal(false);
            setReplyTo({ _id: null, profile: null, content: null });

            // Clear file input (Optional: if you have file input ref)
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Reset the file input
            }
        }
    };

    const takeOverChat = async (phone) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const payload = {
                phone
            };
            const response = await fetch(`${baseUrl}/staff-take-over`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            toast(data.message);
            setSwitchChat(true);
        } catch (error) {
            //  console.log(error);
        }
    };
    const miraOverChat = async (phone) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const payload = {
                phone
            };
            const response = await fetch(`${baseUrl}/mira-take-over`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify(payload)
            });
            const data = await response.json();

            toast(data.message);
            setSwitchChat(false);
        } catch (error) {
            //  console.log(error);
        }
    };

    const scrollToBottom = () => {
        if (chatBoxRef.current) {
            if (window.innerWidth > 768) {
                // For larger screens, scroll to the bottom
                chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
            } else {
                // On mobile, directly show the last message without smooth scrolling
                const lastMessageElement = chatBoxRef.current.lastElementChild;
                if (lastMessageElement) {
                    lastMessageElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    };

    const fetchUserMessages = async (phone, index) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(`${baseUrl}/user?phone=${phone}`, config);

            const data = await response.json();

            setExpiredAt(data?.expires_at);

            // Add random image URL to each user
            const userWithImage = {
                ...data,
                imageUrl: `https://api.dicebear.com/9.x/pixel-art/svg?seed=${index}&r=50&size=100`
            };

            setSelectedUser(userWithImage);
            userHolder = data.profile;

            // Fetch messages

            // Transform message content
            const transformedMessages = data?.messages.map((chat) => {
                let messageContent = chat.content.body;
                if (typeof messageContent === 'object' && messageContent !== null) {
                    if (messageContent.text && messageContent.text.body) {
                        messageContent = messageContent.text.body;
                    } else {
                        // Handle the case where text or body is undefined
                        messageContent = 'No content available'; // Fallback message content
                    }
                } else if (messageContent === null) {
                    // If messageContent is null, provide fallback
                    messageContent = 'No content available';
                }

                let user;
                if (chat.agent === 'staff') {
                    user = `${chat.user?.firstName || ''} ${chat.user?.lastName || ''}`;
                } else if (chat.agent === 'mira') {
                    user = 'mira';
                } else {
                    user = data.profile;
                }

                return {
                    _id: chat._id,
                    phone: chat.phone,
                    content: messageContent,
                    image: chat?.content?.url ? chat?.content?.url : chat?.content?.links,
                    type: chat.content.type,
                    isSent: chat.agent === 'mira' || chat.agent === 'staff',
                    agent: chat.agent,
                    createdAt: chat.createdAt,
                    profile: chat.agent === 'user' ? data.profile : 'mira',
                    whatsapp_id: chat.whatsapp_id,
                    replyTo: chat?.replyTo,
                    delivery: chat?.delivery,
                    user
                };
            });

            // Sort messages by date
            const sortedMessages = transformedMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

            setMessages(sortedMessages);

            // await takeOverChat(phone);
            // Optimistically mark the last message as read in the local users state

            setUsers((prevUsers) =>
                prevUsers.map((user, i) => (i === index ? { ...user, last_chat: { ...user.last_chat, read: true } } : user))
            );

            scrollToBottom();
        } catch (error) {
            // console.log(error);
        }
    };

    const fetchProducts = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(`${ApiUrl.BaseUrl}/order/v2/allparts`, config);
            const data = await response.json();
            const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
            setProducts(decryptedData);
        } catch (error) {
            //  console.log(error);
        }
    };

    const fetchPartType = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(`${ApiUrl.BaseUrl}/order/v2/orderstatuspartypes`, config);
            const data = await response.json();
            const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
            setProductType(decryptedData?.parttypes);
        } catch (error) {
            // console.log(error);
        }
    };

    useEffect(() => {
        if (phoneNumber.phone) {
            fetchUserMessages(phoneNumber.phone, phoneNumber.index);
        }
    }, [phoneNumber.phone, switchChat]);

    useEffect(() => {
        fetchProducts();
        fetchPartType();
    }, []);

    useEffect(() => {
        fetchUsers().then((r) => r);
    }, [chatFilter]);

    useEffect(() => {
        setupSocket();

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, []); // Empty dependency array means this effect runs once
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const onChange = (checked) => {
        setSwitchChat(checked);
        if (checked) {
            miraOverChat(phoneNumber?.phone).then((r) => r);
        } else {
            takeOverChat(phoneNumber?.phone).then((r) => r);
        }
    };

    const handleChatPopClick = (id) => {
        if (open === id) {
            setOpen(null);
            document.getElementById('chat-box-message').classList.remove('no-scroll');
        } else {
            setOpen(id);
            document.getElementById('chat-box-message').classList.add('no-scroll');
        }
    };

    const closePopover = () => {
        setOpen(false);
        document.getElementById('chat-box-message').classList.remove('no-scroll');
    };

    const replyToMessage = (message) => {
        setReplyTo({
            _id: message._id,
            profile: message.profile,
            content: message.content,
            whatsapp_id: message.whatsapp_id
        }); // Set the message to reply to
        closePopover(); // Close the popover
    };

    const actionContent = (message) => (
        <Menu style={{ textAlign: 'center', width: 'fit-content' }}>
            <Menu.Item
                key="reply"
                onClick={() => {
                    setReplyType('text');
                    replyToMessage(message);
                }}
                style={{ padding: '10px' }}
            >
                Reply
            </Menu.Item>
            <Menu.Item key="delete" onClick={() => {}} style={{ padding: '10px' }}>
                Delete
            </Menu.Item>
            <Menu.Item key="edit" onClick={() => {}} style={{ padding: '10px' }}>
                Edit
            </Menu.Item>
        </Menu>
    );

    // eslint-disable-next-line consistent-return
    const imageUploadRequest = async (file) => {
        try {
            toast.loading('file is uploading');
            const token = localStorage.getItem('userToken');
            const rootURL = ApiUrl.BaseUrl;
            const compressedImage = await normFile(file, token, rootURL);
            if (compressedImage) {
                setFileList((prevList) => [...prevList, { uid: file.uid, url: compressedImage, name: file.name }]);
                setImageUrl((prevUrls) => [...prevUrls, compressedImage]); // Store as an array
                toast.dismiss();

                return {
                    status: 'done',
                    url: compressedImage
                };
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    };

    const props = {
        name: 'file',
        multiple: true,
        accept,
        customRequest: ({ file, onSuccess, onError }) => {
            // Your image upload request logic
            imageUploadRequest(file).then(onSuccess).catch(onError);
        },
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                // Update file list when upload is successful
            } else if (status === 'error') {
                // Handle file upload error
                // console.log(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: (file) => {
            // Remove the file from the file list when user removes it
            setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
        },
        fileList // Pass the fileList to the Upload component for control
    };

    // Handle resizing for mobile screens
    const handleResize = () => {
        if (window.innerWidth < 768) {
            if (isChatBoxVisible) {
                setSenderListVisible(false);
                document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'none');
                if (isAtBottom) {
                    scrollToBottom();
                }
            } else {
                setSenderListVisible(true);
                document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'flex');
            }
        } else {
            setSenderListVisible(true);
            setChatBoxVisible(true);
            document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'flex');
            if (isAtBottom) {
                scrollToBottom();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check
        scrollToBottom();
        return () => window.removeEventListener('resize', handleResize);
    }, [isChatBoxVisible, isAtBottom]);

    const showSenderList = () => {
        setSenderListVisible(true);
        setChatBoxVisible(false);
        document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'none');
    };

    const hideSenderList = () => {
        setSenderListVisible(false);
        setChatBoxVisible(true);
        document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'flex');
        scrollToBottom();
    };

    // Filter user based on search term and chat filter
    useEffect(() => {
        const applyFilters = () => {
            const filtered = users.filter((user) => {
                const matchesSearchTerm = user.profile.toLowerCase().includes(searchTerm.toLowerCase());
                const lastChat = user.last_chat;

                const isUnread = lastChat && !lastChat.read;

                switch (chatFilter) {
                    case 'all':
                        return matchesSearchTerm;
                    case 'read':
                        return matchesSearchTerm && lastChat && lastChat.read;
                    case 'unread':
                        return matchesSearchTerm && isUnread;
                    default:
                        return false;
                }
            });

            setFilteredUsers(filtered);
        };

        applyFilters();
    }, [chatFilter, searchTerm, users]);

    // placing order
    const placeOrderFunction = async () => {
        try {
            setPlaceOrderLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const localId = Math.random().toString(36).substring(2, 15);
            const orderData = {
                customerId,
                customerName,
                orderNotes: 'Order placed via chat',
                local_id: localId,
                phoneNumber: customerPhone,
                location: customerLocation,
                src: 'Web',
                items: selectedProducts.map((product) => ({
                    skuId: product._id,
                    qty: product.qty || 1,
                    partType: product.partType
                }))
            };

            // Optional: Add lat and lng if available
            if (customerLocation.lat && customerLocation.lng) {
                orderData.lat = customerLocation.lat;
                orderData.lng = customerLocation.lng;
            }

            const datas = orderData;
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

            const url = `${ApiUrl.BaseUrl}/order/v2/placemiraorder`;
            const response = await fetch(url, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });
            const result = await response.json();

            if (result.status === true) {
                setPlaceOrderLoading(false);
                setOrderNo(result?.data);

                setTab('order-receipt');
                // Return the result with order_id
                // eslint-disable-next-line consistent-return
                return {
                    order_id: result.data, // Assuming order_id is part of the response
                    ...result
                };
            }
            toast.error(result.message);
            setPlaceOrderLoading(false);
        } catch (error) {
            // console.log(error);
            setPlaceOrderLoading(false);
        }
    };

    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true
        };
    }, [arrow]);

    // Function to upload or use the audio message
    const sendAudioFile = () => {
        // const audioUrl = URL.createObjectURL(blob); // Create a URL for the audio blob
        setAudioModal(false);
        const newMessage = {
            phone: selectedUser.phone,
            content: {
                body: message,
                type: 'audio',
                url: audioFile
            },
            user_id: localStorage.getItem('userId'),
            replyTo: replyTo.whatsapp_id
        };

        socket.emit('mira_chat', newMessage);

        // Create the message object with audio content
        const transformedMessage = {
            _id: Date.now(),
            phone: selectedUser.phone,
            content: '', // Audio file URL
            image: audioFile,
            type: 'audio',
            isSent: 'mira' || 'staff',
            agent: 'staff',
            user: `${localStorage.getItem('userFirstName')} ${localStorage.getItem('userLastName')}`,
            createdAt: new Date().toISOString()
        };

        setMessages((prevMessages) => [...prevMessages, transformedMessage]);
        setAudioFile('');
        setRecordingTime(0);
    };

    // To start recording
    const startRecording = () => {
        setAudioModal(true);
        setAudioChunks([]);
        setIsRecording(true);

        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;

            // Event handler for when there's data available
            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    // Append audio data chunk to state

                    setAudioChunks((prevChunks) => [...prevChunks, event.data]);
                }
            };

            // Start recording
            mediaRecorder.start();
            // setIsRecording(true);

            // Start the timer for recording duration
            recordingTimerRef.current = setInterval(() => {
                setRecordingTime((prev) => prev + 1);
            }, 1000);
        });
    };

    // Upload the recorded audio
    const uploadAudio = async (audioBlob) => {
        if (!audioBlob) return; // Ensure there's an audio file to upload

        const formData = new FormData();
        formData.append('file', audioBlob, 'audio_recording.webm'); // Appending the recorded audio

        try {
            const token = localStorage.getItem('userToken'); // Get token for authentication
            const rootURL = ApiUrl.BaseUrl; // Base URL for API calls

            const response = await fetch(`${rootURL}/channelpartner/uploadpartsimage`, {
                method: 'POST',
                headers: {
                    'auth-token': token
                },
                body: formData // Sending the FormData containing the audio file
            });

            const result = await response.json();

            if (result.status === true) {
                setAudioFile(result.data);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    // To stop recording
    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop(); // Stop the recording
            setIsRecording(false);

            // Stop all tracks to release microphone access
            const tracks = mediaRecorderRef.current.stream.getTracks();
            tracks.forEach((track) => track.stop());

            // Check audio chunks inside the onstop handler
            mediaRecorderRef.current.onstop = () => {
                // Use a function to capture the latest audioChunks
                setAudioChunks((prevChunks) => {
                    if (prevChunks.length > 0) {
                        const audioBlob = new Blob(prevChunks, { type: 'audio/webm' });
                        const audioUrl = URL.createObjectURL(audioBlob); // Create URL for playback

                        setRecordedAudio(audioBlob); // Save Blob for uploading
                        setAudioPreviewUrl(audioUrl); // Save URL for audio playback

                        // Proceed with audio upload if necessary
                        uploadAudio(audioBlob).then((r) => r);
                        setRecordingTime(0);
                    } else {
                        // console error
                    }
                    return prevChunks; // Return the previous state
                });
            };
        }
    };

    const mediaContent = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left',
                alignItems: 'left',
                textAlign: 'left',
                backgroundColor: '#f9f9f9',
                padding: '10px',
                borderRadius: '10px',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                width: '150px'
            }}
        >
            <Button
                style={{
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                type="button"
                onClick={() => {
                    setAccept('application/pdf');
                    setMessageType('document');
                    setImageModal(true);
                    closePopover();
                }}
            >
                Document <FilePdfOutlined />
            </Button>
            <Button
                style={{
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                type="button"
                onClick={() => {
                    setAccept('image/*');
                    setMessageType('image');
                    setImageModal(true);
                    closePopover();
                }}
            >
                Image <FileImageOutlined />
            </Button>
            <Button
                style={{
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                type="button"
                onClick={() => {
                    setRecordingTime(0);
                    startRecording();
                }}
            >
                Audio <AudioOutlined />
            </Button>
            <Button
                style={{
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                type="button"
                onClick={() => {
                    setAccept('application/video');
                    setMessageType('video');
                    setImageModal(true);
                    closePopover();
                }}
            >
                Video <VideoCameraAddOutlined />
            </Button>
        </div>
    );

    const handleMenuClick = (e) => {
        if (e.key === 'customer-cart') {
            window.location.href = '/mira/orders';
        } else if (e.key === 'place-order') {
            setPlaceOrder(true);
        }
    };

    const menu = (
        <Menu onClick={handleMenuClick} style={{ textAlign: 'center', width: '200px' }}>
            <Menu.Item key="assign-task" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                Create task
            </Menu.Item>
            <Menu.Item key="customer-cart" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                View pro-forma
            </Menu.Item>
            <Menu.Item key="place-order" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                Create pro-forma
            </Menu.Item>
        </Menu>
    );

    const computeFinalQty = (qty, partQtyInPieces, partType) => {
        let finalQty = qty;
        let finalPartType = partType;

        if (partQtyInPieces && partType?.toLowerCase() !== 'pieces') {
            finalQty = qty * partQtyInPieces;
            finalPartType = 'Pieces';
        } else if (partQtyInPieces && partType?.toLowerCase() === 'pieces') {
            finalQty = qty;
            finalPartType = 'Pieces';
        } else {
            finalPartType = 'Pieces';
        }

        return { finalQty, finalPartType };
    };

    useEffect(() => {
        // Update the countdown every second
        const intervalId = setInterval(() => {
            setTimeRemaining(calculateChatExpiration(expiredAt));
        }, 1000);

        // Clear the interval when the component is unmounted or expiredAt changes
        return () => clearInterval(intervalId);
    }, [expiredAt]);

    return (
        <div className="chat-inbox-container">
            {isSenderListVisible && (
                <div className="senderList">
                    <div className="search-containter">
                        <select value={chatFilter} onChange={(e) => setChatFilter(e.target.value)} className="chat-filter-select">
                            <option value="all">Show all chats</option>
                            <option value="read">Read chats</option>
                            <option value="unread">Unread chats</option>
                        </select>
                        <input
                            type="text"
                            placeholder="Search customer"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="search-input"
                        />
                    </div>
                    {loading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                            }}
                        >
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 48, color: '#FF740F' }} spin />} />
                        </div>
                    ) : (
                        <div>
                            {filteredUsers.map((user, index) => {
                                const lastMessage = messages.filter((msg) => msg.phone === user.phone).slice(-1)[0]?.content || user.phone;
                                const hasNewMessage = messages.some(
                                    (msg) => msg.phone === user.phone && !msg.isSent && msg.agent !== 'user'
                                );
                                const lastChatTime = user?.last_chat?.createdAt;
                                const isLastMsgRead = user?.last_chat?.read === false;

                                return (
                                    <div
                                        className="sender d-flex justify-content-between"
                                        key={user._id}
                                        onClick={() => {
                                            if (window.innerWidth < 768) {
                                                hideSenderList();
                                            }
                                            setSelectedUser(user.profile);
                                            setPhoneNumber({ phone: user.phone, index });
                                            fetchUserMessages(user.phone, index).then((r) => r);
                                        }}
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={() => fetchUserMessages(user.phone, index)}
                                    >
                                        <div className="d-flex">
                                            <div className="sender-avatar">{user?.profile?.slice(0, 2).toUpperCase()}</div>
                                            <div className="sender-content">
                                                <div className="senderName">{user?.profile}</div>
                                                <div className="senderMessage">
                                                    {hasNewMessage || isLastMsgRead ? (
                                                        <strong className="new_message_indicator">New Message</strong>
                                                    ) : (
                                                        lastMessage.slice(0, 10)
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column justify-content-end">
                                            {/* Dynamically display time ago */}
                                            {lastChatTime && <small>{timeAgo(lastChatTime)}</small>}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}

            {selectedUser && isChatBoxVisible && (
                <div className="chatBox">
                    <div className="chat-box-header">
                        <div className="chat-box-header-content">
                            <span className="back">
                                <LeftOutlined onClick={showSenderList} />
                            </span>
                            <div className="sender-avatar">{selectedUser?.profile?.slice(0, 2).toUpperCase()}</div>
                            <div className="ml-5">
                                <p className="user-name">
                                    <strong>{selectedUser?.profile || 'Loading...'}</strong>
                                </p>
                                <div className="d-flex flex-column ">
                                    <small className="senderMessage">{selectedUser?.phone}</small>
                                    {expiredAt && (
                                        <Tag style={{ color: '#FA3246', backgroundColor: '#FFEBED', border: 'none' }}>
                                            <FieldTimeOutlined style={{ marginRight: '2px', marginBottom: '2px' }} />
                                            {timeRemaining}
                                        </Tag>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="customer-option-container">
                            <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                                <Button
                                    style={{
                                        backgroundColor: '#0A2938',
                                        color: '#fff',
                                        border: 'none',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    Actions <DownOutlined style={{ marginLeft: '5px', fontSize: '14px', marginTop: '3px' }} />
                                </Button>
                            </Dropdown>
                        </div>
                    </div>

                    <div className="chat-box-body">
                        <ChatBoxMessage
                            messages={messages}
                            chatBoxRef={chatBoxRef}
                            phoneNumber={phoneNumber}
                            actionContent={actionContent}
                            open={open}
                            handleChatPopClick={handleChatPopClick}
                        />

                        {replyType === 'text' ? (
                            <span>
                                {replyTo.content && (
                                    <div className="replyToMessage">
                                        <p style={{ fontSize: '12px' }}>
                                            <strong className="profile">{replyTo.profile}</strong>
                                            <br /> {replyTo.content}
                                        </p>
                                        <button
                                            className="close-btn"
                                            type="button"
                                            onClick={() => {
                                                setReplyTo({ _id: null, profile: null, content: null });
                                            }}
                                        >
                                            x
                                        </button>
                                    </div>
                                )}
                            </span>
                        ) : null}

                        <div className={`chat-menu-list ${chatMenuVisibleMobile ? 'visible' : ''}`}>
                            <div
                                className="chat-menu-item"
                                onClick={() => {
                                    setAccept('image/*');
                                    setMessageType('image');
                                    setImageModal(true);
                                }}
                                role="button" // Accessible button role
                                tabIndex="0"
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        setAccept('image/*');
                                        setMessageType('image');
                                        setImageModal(true);
                                    }
                                }}
                            >
                                <span className="chat-menu-item-icon">
                                    <FileImageOutlined />
                                </span>
                                <span className="chat-menu-item-text">Image</span>
                            </div>
                            <div
                                className="chat-menu-item"
                                onClick={() => {
                                    setAccept('application/pdf');
                                    setMessageType('document');
                                    setImageModal(true);
                                }}
                                role="button" // Accessible button role
                                tabIndex="0"
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        setAccept('application/pdf');
                                        setMessageType('document');
                                        setImageModal(true);
                                    }
                                }}
                            >
                                <span className="chat-menu-item-icon">
                                    <FilePdfOutlined />
                                </span>
                                <span className="chat-menu-item-text">Document</span>
                            </div>
                            <div
                                className="chat-menu-item"
                                onClick={() => {
                                    setRecordingTime(0);
                                    startRecording();
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        setRecordingTime(0);
                                        startRecording();
                                    }
                                }}
                                role="button"
                                tabIndex="0"
                            >
                                <span className="chat-menu-item-icon">
                                    <AudioOutlined />
                                </span>
                                <span className="chat-menu-item-text">Audio</span>
                            </div>
                            <div
                                className="chat-menu-item"
                                onClick={() => {
                                    setAccept('application/video');
                                    setMessageType('video');
                                    setImageModal(true);
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        setRecordingTime(0);
                                        startRecording();
                                    }
                                }}
                                role="button"
                                tabIndex="0"
                            >
                                <span className="chat-menu-item-icon">
                                    <VideoCameraAddOutlined />
                                </span>
                                <span className="chat-menu-item-text">Video</span>
                            </div>
                        </div>

                        <div className={`chat-box-footer ${chatMenuVisibleMobile ? 'footer-up' : ''}`}>
                            <input
                                type="text"
                                placeholder="Send message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                            />
                            <button className="ml-5" type="button" onClick={sendMessage}>
                                <SendOutlined />
                            </button>
                            <Popover
                                content={mediaContent}
                                trigger="click"
                                open={chatMenuVisible}
                                onOpenChange={setChatMenuVisible}
                                placement="topLeft"
                            >
                                <button
                                    className="ml-5 mira-mobile-hide"
                                    style={
                                        chatMenuVisible
                                            ? {
                                                  backgroundColor: '#F6F6F6',
                                                  color: '#7B808C'
                                              }
                                            : { backgroundColor: '#4B6EFF' }
                                    }
                                    type="button"
                                    onClick={() => setChatMenuVisible(!chatMenuVisible)}
                                >
                                    {chatMenuVisible ? <CloseOutlined /> : <PlusOutlined />}
                                </button>
                            </Popover>
                            <button
                                className="ml-5 mira-desktop-hide"
                                style={
                                    chatMenuVisibleMobile
                                        ? {
                                              backgroundColor: '#F6F6F6',
                                              color: '#7B808C'
                                          }
                                        : { backgroundColor: '#4B6EFF' }
                                }
                                type="button"
                                onClick={() => setChatMenuVisibleMobile(!chatMenuVisibleMobile)}
                            >
                                {chatMenuVisibleMobile ? <CloseOutlined /> : <PlusOutlined />}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Modal
                open={imageModal}
                onCancel={() => {
                    setImageModal(false);
                    setImageUrl([]);
                    setFileList([]);
                }}
                footer={null}
                width={600}
            >
                <div style={{ padding: '20px' }}>
                    <Upload.Dragger {...props} style={{ marginBottom: '10px' }}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">Support for single or bulk upload.</p>
                    </Upload.Dragger>
                    <div style={{ marginBottom: '30px', marginTop: '30px' }}>
                        {imageUrl?.map((url, index) => {
                            const fileExtension = url.split('.').pop().toLowerCase();

                            // Handle image, video, and PDF previews based on file type
                            if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
                                return (
                                    <img
                                        key={index}
                                        src={url}
                                        alt={`uploaded-${index}`}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'contain',
                                            marginRight: '10px'
                                        }}
                                    />
                                );
                            }
                            if (['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension)) {
                                return (
                                    <video
                                        key={index}
                                        controls
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'contain',
                                            marginRight: '10px'
                                        }}
                                    >
                                        <source src={url} type="video/mp4" />
                                        <source src={url} type="video/webm" />
                                        <source src={url} type="video/ogg" />
                                        <source src={url} type="video/mov" />
                                        {/* Track for captions */}
                                        <track kind="captions" srcLang="en" label="English captions" default />
                                    </video>
                                );
                            }
                            if (fileExtension === 'pdf') {
                                return (
                                    <iframe
                                        key={index}
                                        src={url}
                                        title={`uploaded-pdf-${index}`}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'contain',
                                            marginRight: '10px',
                                            border: '1px solid #ccc'
                                        }}
                                    />
                                );
                            }
                            return <p key={index}>Unsupported file type: {fileExtension}</p>;
                        })}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: 'calc(89% - 20px)',
                            padding: '10px',
                            position: 'absolute',
                            bottom: '0',
                            marginBottom: '10px',
                            marginTop: '50px',
                            paddingTop: '10px',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            border: '1px solid #f0f0f0'
                        }}
                    >
                        <input
                            style={{
                                width: 'calc(100% - 50px)',
                                padding: '5px',
                                borderRadius: '5px',
                                border: 'none',
                                outline: 'none',
                                height: '30px'
                            }}
                            type="text"
                            placeholder="Type a message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                        />
                        <button
                            type="button"
                            onClick={() => {
                                sendMessage();
                            }}
                            style={{
                                backgroundColor: '#F6F6F6',
                                color: '#7B808C',
                                border: 'none',
                                padding: '10px 20px',
                                borderRadius: '100px',
                                marginLeft: '10px',
                                cursor: 'pointer',
                                fontSize: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <SendOutlined />
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal open={placeOrder} onCancel={() => setPlaceOrder(false)} footer={null} width={800}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                    <div className="order-modal-header" style={{ marginBottom: '20px' }}>
                        <span>
                            {tab === 'products' && <h3>Place pro-forma</h3>}
                            {tab === 'place-order' && (
                                <div>
                                    <span
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => setTab('products')}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') {
                                                setTab('place-order');
                                            }
                                        }}
                                        style={{
                                            width: 'fit-content',
                                            backgroundColor: 'transparent',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <ArrowLeftOutlined style={{ fontSize: '30px', marginRight: '5px' }} />
                                        <h3>Place pro-forma</h3>
                                    </span>
                                </div>
                            )}
                            {tab === 'customer-details' && (
                                <span
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => setTab('place-order')}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            setTab('place-order');
                                        }
                                    }}
                                    style={{
                                        width: 'fit-content',
                                        backgroundColor: 'transparent',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ArrowLeftOutlined style={{ fontSize: '30px', marginRight: '5px' }} />
                                    <h3>Customer details</h3>
                                </span>
                            )}
                            {/* {tab === 'order-receipt' && <h3>Order receipt</h3>} */}
                            {tab === 'products' && <p>Search for an item and place order</p>}
                        </span>
                        {tab === 'products' && (
                            <span>
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Search for Item"
                                    style={{ width: '200px' }}
                                    options={products.map((product) => ({
                                        value: product._id,
                                        label: product.sku
                                    }))}
                                    filterOption={(inputValue, option) => {
                                        // This function is used to filter options in a Select component
                                        // It compares the input value with the label of each option

                                        // Convert both the input and the option label to uppercase for case-insensitive comparison
                                        const upperInput = inputValue.toUpperCase();

                                        // Check if the option label is a string
                                        if (typeof option.label === 'string') {
                                            // If it's a string, directly check if the uppercase input is included in the uppercase label
                                            return option.label.toUpperCase().indexOf(upperInput) !== -1;
                                        }

                                        // If the label is not a string (e.g., it might be a number or other type)
                                        // Convert it to a string before comparing
                                        return String(option.label).toUpperCase().indexOf(upperInput) !== -1;
                                    }}
                                    value={product}
                                    onChange={(value) => {
                                        setProduct(value);

                                        const product = products.find((product) => product._id === value);
                                        if (product) {
                                            setSelectedProducts([...selectedProducts, product]);
                                        } else {
                                            //  console.log('Product not found for value:', value);
                                        }
                                    }}
                                />
                            </span>
                        )}
                    </div>
                    <div className="order-modal-body table-responsive">
                        {tab === 'products' ? (
                            <Table
                                rowKey={(row) => row._id}
                                dataSource={selectedProducts}
                                columns={[
                                    {
                                        title: 'Product image',
                                        dataIndex: 'partsImage',
                                        key: 'partsImage',
                                        render: (partsImage) => (
                                            <img
                                                src={partsImage}
                                                alt="product"
                                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                            />
                                        )
                                    },
                                    {
                                        title: 'Product name',
                                        dataIndex: 'sku',
                                        key: 'sku'
                                    },
                                    {
                                        title: 'Product price',
                                        dataIndex: 'garagePrice',
                                        key: 'garagePrice'
                                    },

                                    {
                                        title: 'Action',
                                        dataIndex: 'action',
                                        key: 'action',
                                        render: (_, record) => (
                                            <Button
                                                type="primary"
                                                danger
                                                onClick={() => {
                                                    const updatedProducts = selectedProducts.filter((p) => p._id !== record._id);
                                                    setSelectedProducts(updatedProducts);
                                                }}
                                            >
                                                <CloseOutlined />
                                            </Button>
                                        )
                                    }
                                ]}
                            />
                        ) : tab === 'place-order' ? (
                            <div>
                                {selectedProducts.map((product) => {
                                    // Calculate final quantity and part type once per product
                                    const { finalQty, finalPartType } = computeFinalQty(
                                        product.qty || 0,
                                        product.partQtyInPieces,
                                        product.partType || ''
                                    ); // Compute for each product

                                    return (
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '20px' }}
                                            key={product._id}
                                        >
                                            <div
                                                style={{
                                                    width: '140px',
                                                    height: '140px',
                                                    objectFit: 'cover',
                                                    backgroundColor: '#CED4D7',
                                                    borderRadius: '10px',
                                                    padding: '5px'
                                                }}
                                            >
                                                <img
                                                    src={product.partsImage}
                                                    alt="product"
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }}
                                                />
                                            </div>
                                            <div>
                                                <p style={{ fontSize: '14px', marginBottom: '2px', paddingBottom: '0px' }}>
                                                    <strong>Product name:</strong> {product.sku}
                                                </p>
                                                <p style={{ fontSize: '14px', marginBottom: '2px', paddingBottom: '0px' }}>
                                                    <strong>Product price:</strong> {product.garagePrice}
                                                </p>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '10px',
                                                        marginTop: '10px'
                                                    }}
                                                >
                                                    <span>
                                                        <strong>Part type:</strong>{' '}
                                                        <Select
                                                            showSearch
                                                            allowClear
                                                            placeholder="Select part type"
                                                            options={productType.map((type) => ({
                                                                value: type.name,
                                                                label: type.name
                                                            }))}
                                                            value={product.partType} // Using finalPartType here
                                                            onChange={(value) => {
                                                                const updatedProducts = selectedProducts.map((p) =>
                                                                    p._id === product._id ? { ...p, partType: value } : p
                                                                );
                                                                setSelectedProducts(updatedProducts);
                                                            }}
                                                            filterOption={(input, option) =>
                                                                option.label.toLowerCase().startsWith(input.toLowerCase())
                                                            }
                                                            style={{ width: '100px', marginLeft: '40px' }}
                                                        />
                                                    </span>
                                                </div>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '10px',
                                                        marginTop: '10px'
                                                    }}
                                                >
                                                    <span>
                                                        <strong>Quantity</strong>
                                                    </span>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '10px',
                                                            marginLeft: '40px'
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                backgroundColor: '#193B56',
                                                                padding: '5px',
                                                                borderRadius: '5px'
                                                            }}
                                                            onClick={() => {
                                                                const updatedProducts = selectedProducts.map((p) =>
                                                                    p._id === product._id
                                                                        ? {
                                                                              ...p,
                                                                              qty: Math.max(0, (p.qty || 0) - 1)
                                                                          }
                                                                        : p
                                                                );
                                                                setSelectedProducts(updatedProducts);
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' || e.key === ' ') {
                                                                    const updatedProducts = selectedProducts.map((p) =>
                                                                        p._id === product._id
                                                                            ? {
                                                                                  ...p,
                                                                                  qty: Math.max(0, (p.qty || 0) - 1)
                                                                              }
                                                                            : p
                                                                    );
                                                                    setSelectedProducts(updatedProducts);
                                                                }
                                                            }}
                                                        >
                                                            <MinusOutlined style={{ color: '#fff' }} />
                                                        </span>
                                                        <span>{product.qty || 0}</span> {/* Using finalQty here */}
                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                backgroundColor: '#193B56',
                                                                padding: '5px',
                                                                borderRadius: '5px'
                                                            }}
                                                            onClick={() => {
                                                                const updatedProducts = selectedProducts.map((p) =>
                                                                    p._id === product._id
                                                                        ? {
                                                                              ...p,
                                                                              qty: (p.qty || 0) + 1
                                                                          }
                                                                        : p
                                                                );
                                                                setSelectedProducts(updatedProducts);
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' || e.key === ' ') {
                                                                    const updatedProducts = selectedProducts.map((p) =>
                                                                        p._id === product._id
                                                                            ? {
                                                                                  ...p,
                                                                                  qty: (p.qty || 0) + 1
                                                                              }
                                                                            : p
                                                                    );
                                                                    setSelectedProducts(updatedProducts);
                                                                }
                                                            }}
                                                        >
                                                            <PlusOutlined style={{ color: '#fff' }} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <p style={{ color: '#FF740F', marginTop: '10px' }}>
                                                    Converted Quantity: {finalQty} {finalPartType}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : tab === 'customer-details' ? (
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }} />
                                {customerLoader ? (
                                    <div style={{ textAlign: 'center', margin: '20px 0' }}>
                                        <p style={{ fontSize: '16px', marginBottom: '10px' }}>Loading customer details...</p>
                                    </div>
                                ) : (
                                    <div>
                                        <div style={{ marginBottom: '20px' }}>
                                            <p style={{ fontSize: '14px', marginBottom: '5px' }}>Retailer’s name</p>
                                            <Input
                                                placeholder="Customer name"
                                                style={{ width: '100%', padding: '10px' }}
                                                value={customerName}
                                                onChange={(e) => setCustomerName(e.target.value)}
                                            />
                                        </div>
                                        <div style={{ marginBottom: '20px' }}>
                                            <p style={{ fontSize: '14px', marginBottom: '5px' }}>Phone number</p>
                                            <Input
                                                placeholder="Phone number"
                                                style={{ width: '100%', padding: '10px' }}
                                                value={customerPhone}
                                                onChange={(e) => setCustomerPhone(e.target.value)}
                                            />
                                        </div>
                                        <div style={{ marginBottom: '20px' }}>
                                            <p style={{ fontSize: '14px', marginBottom: '5px' }}>Location</p>
                                            <Input
                                                placeholder="Location"
                                                style={{ width: '100%', padding: '10px' }}
                                                value={customerLocation}
                                                onChange={(e) => setCustomerLocation(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : tab === 'order-receipt' ? (
                            <div className="order-receipt-content">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div className="logo">
                                        <img src={logo} alt="logo" style={{ width: '150px', height: '50px', objectFit: 'contain' }} />
                                    </div>
                                    <div className="order-receipt-header">
                                        <h1 style={{ fontSize: '18px' }}>Garage Mobility LTD</h1>
                                        <a href="mailto:info@garagemobility.com">info@garagemobility.com</a>
                                    </div>
                                </div>
                                <hr />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: '20px',
                                        marginBottom: '20px'
                                    }}
                                >
                                    <div>
                                        <h1 style={{ fontSize: '14px' }}>INVOICE TO:</h1>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>Customer Name:</strong> {customerName}
                                        </p>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>Phone Number:</strong> {customerPhone}
                                        </p>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>Location:</strong> {customerLocation}
                                        </p>
                                    </div>
                                    <div>
                                        <h1 style={{ fontSize: '14px' }}>
                                            <strong>Order No:</strong> {orderNo}
                                        </h1>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>DateTime:</strong> {new Date().toLocaleString()}
                                        </p>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>Relationship Manager:</strong> {localStorage.getItem('userFirstName')}{' '}
                                            {localStorage.getItem('userLastName')}
                                        </p>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table style={{ width: '100%' }}>
                                        <thead style={{ backgroundColor: '#1A3855', color: '#fff', width: '100%' }}>
                                            <tr style={{ fontSize: '14px', fontWeight: 'bold', width: '100%' }}>
                                                <th>ITEM DESCRIPTION</th>
                                                <th>QTY</th>
                                                <th>PART TYPE</th>
                                                <th>AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ fontSize: '14px' }}>
                                            {selectedProducts.map((product) => {
                                                const { finalQty, finalPartType } = computeFinalQty(
                                                    product.qty || 0,
                                                    product.partQtyInPieces,
                                                    product.partType || ''
                                                );
                                                product.qty = finalQty;
                                                product.partType = finalPartType;
                                                return (
                                                    <tr key={product._id}>
                                                        <td>{product.sku}</td>
                                                        <td>{finalQty}</td>
                                                        <td>{finalPartType}</td>
                                                        <td>{(product.garagePrice * finalQty).toFixed(2)}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                <td colSpan={3} style={{ fontWeight: 'bold' }}>
                                                    Total Payable:
                                                </td>
                                                <td style={{ fontWeight: 'bold' }}>
                                                    {selectedProducts
                                                        .reduce((totalAmount, product) => {
                                                            const { finalQty } = computeFinalQty(
                                                                product.qty || 0,
                                                                product.partQtyInPieces,
                                                                product.partType || ''
                                                            );
                                                            return totalAmount + product.garagePrice * finalQty;
                                                        }, 0)
                                                        .toFixed(2)}
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="order-modal-footer" style={{ marginTop: '20px' }}>
                        {selectedProducts.length > 0 && (
                            <>
                                {tab === 'products' && (
                                    <Button className="order-modal-footer-btn" onClick={() => setTab('place-order')}>
                                        Continue
                                    </Button>
                                )}
                                {tab === 'place-order' && (
                                    <Button
                                        disabled={selectedProducts.some((product) => !product.qty || !product.partType)}
                                        className="order-modal-footer-btn"
                                        onClick={() => {
                                            setTab('customer-details');
                                            fetchCustomer().then((r) => r);
                                        }}
                                    >
                                        Continue
                                    </Button>
                                )}
                                {tab === 'customer-details' && (
                                    <Button
                                        disabled={!customerName || !customerPhone || !customerLocation}
                                        loading={placeOrderLoading}
                                        className="order-modal-footer-btn"
                                        onClick={() => setTab('order-receipt')}
                                    >
                                        Continue
                                    </Button>
                                )}
                                {tab === 'order-receipt' && (
                                    <div style={{ display: 'flex', textAlign: 'center' }}>
                                        <Button
                                            className="order-modal-footer-btn"
                                            onClick={async () => {
                                                try {
                                                    setReceiptLoading(true);

                                                    const orderResponse = await placeOrderFunction();

                                                    if (!orderResponse || !orderResponse.order_id) {
                                                        toast.error('Failed to place pro-forma');
                                                    }
                                                    const orderId = orderResponse.order_id;

                                                    // Import html2pdf
                                                    const html2pdf = (await import('html2pdf.js')).default;

                                                    // Get the content to be converted to PDF
                                                    const content = document.querySelector('.order-receipt-content');

                                                    // Generate PDF using html2pdf
                                                    const opt = {
                                                        margin: 1,
                                                        filename: 'order_receipt.pdf',
                                                        image: { type: 'jpeg', quality: 0.98 },
                                                        html2canvas: { scale: 2, useCORS: true },
                                                        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
                                                    };

                                                    // Generate PDF and get blob
                                                    const pdfBlob = await new Promise((resolve, reject) => {
                                                        html2pdf()
                                                            .set(opt)
                                                            .from(content)
                                                            .toPdf()
                                                            .outputPdf('blob')
                                                            .then(resolve)
                                                            .catch(reject);
                                                    });

                                                    // Create a File object from the PDF blob
                                                    const pdfFile = new File([pdfBlob], 'proforma.pdf', { type: 'application/pdf' });

                                                    // Create an object with the required properties
                                                    const fileObject = new File([pdfBlob], 'proforma.pdf', {
                                                        type: 'application/pdf',
                                                        lastModified: Date.now()
                                                    });
                                                    fileObject.uid = `rc-upload-${Date.now()}`;
                                                    fileObject.originalname = 'order_receipt.pdf';
                                                    // Upload the PDF file
                                                    const response = await imageUploadRequest(fileObject);

                                                    setReceiptLoading(false);
                                                    if (response && response.status === 'done') {
                                                        // Set the message and message type
                                                        const orderReceiptMessage = `${customerName} Pro-forma enquiry`;
                                                        const orderReceiptType = 'document';
                                                        const imageUrl = Array.isArray(response.url) ? response.url : [response.url];

                                                        const newMessage = {
                                                            phone: selectedUser.phone,
                                                            content: {
                                                                body: orderReceiptMessage,
                                                                type: orderReceiptType, // 'text' or 'image',
                                                                url:
                                                                    orderReceiptType === 'image' || orderReceiptType === 'document'
                                                                        ? imageUrl
                                                                        : ''
                                                            },
                                                            user_id: localStorage.getItem('userId'),
                                                            order_id: orderId,
                                                            order_type: 'proforma',
                                                            replyTo: replyTo.whatsapp_id
                                                        };

                                                        socket.emit('mira_chat', newMessage);

                                                        // Transform message content
                                                        const transformedMessage = {
                                                            phone: selectedUser.phone,
                                                            content: orderReceiptMessage,
                                                            type: orderReceiptType,
                                                            isSent: 'mira' || 'staff',
                                                            agent: 'staff',
                                                            image:
                                                                orderReceiptType === 'image' || orderReceiptType === 'document'
                                                                    ? imageUrl
                                                                    : '',
                                                            user: `${localStorage.getItem('userFirstName')} ${localStorage.getItem(
                                                                'userLastName'
                                                            )}`,
                                                            _id: Math.random().toString(36).substring(2, 15),
                                                            createdAt: new Date().toISOString()
                                                        };

                                                        setMessages((prevMessages) => [...prevMessages, transformedMessage]);

                                                        setMessage('');
                                                        setMessageType('text');
                                                        setImageUrl([]);
                                                        setPlaceOrder(false);
                                                        setTab('products');
                                                        setSelectedProducts([]);
                                                        setCustomerName('');
                                                        setCustomerPhone('');
                                                        setCustomerLocation('');
                                                        setOrderNo('');
                                                    }
                                                } catch (error) {
                                                    toast.error('Failed to send order receipt');
                                                    setReceiptLoading(false);
                                                }
                                            }}
                                            style={{ display: 'flex', alignItems: 'center' }}
                                            loading={receiptLoading}
                                        >
                                            <img
                                                src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                                                alt="PDF icon"
                                                height={20}
                                                style={{ marginRight: '5px' }}
                                            />
                                            {receiptLoading ? 'Sending...' : 'Send to customer'}
                                        </Button>
                                        <Button
                                            onClick={() => setTab('place-order')}
                                            style={{
                                                marginLeft: '5px',
                                                color: '#FF7410',
                                                textDecoration: 'underline',

                                                fontSize: '16px'
                                            }}
                                        >
                                            <EditOutlined /> Edit pro-forma
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </Modal>
            <Modal
                title="Mira audio recorder"
                open={audioModal}
                onCancel={() => {
                    setAudioModal(false);
                    stopRecording();
                    setRecordingTime(0);
                    setAudioFile('');
                }}
                footer={null}
                width={400}
            >
                <div>
                    {isRecording ? (
                        <div className="recording-indicator">
                            <div className="pulse-animation" />
                            <p>Recording... {recordingTime} seconds</p>
                            <Button
                                style={{ backgroundColor: 'transparent', borderColor: '#FF740F', color: '#FF740F', width: 'fit-content' }}
                                onClick={stopRecording}
                                className="mt-4"
                            >
                                Stop Recording
                            </Button>
                        </div>
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <audio controls>
                                <source src={audioPreviewUrl} type="audio/webm" />
                                <track kind="captions" srcLang="en" src="path-to-your-captions.vtt" />
                                Your browser does not support the audio element.
                            </audio>

                            <Button
                                style={{ backgroundColor: 'transparent', borderColor: '#FF740F', color: '#FF740F', width: 'fit-content' }}
                                onClick={sendAudioFile}
                                className="mt-4"
                            >
                                Send Recording
                            </Button>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default Index;
